/* =========== Google Fonts ============ */
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* =============== Globals ============== */
* {
  /* font-family: "Ubuntu", sans-serif; */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --blue: #34444c;
  --white: #fff;
  --gray: #f5f5f5;
  --black1: #222;
  --black2: #999;
}

html {
    /* font-family: var(--ff-roboto); */
    font-size: inherit !important;
    /* scroll-behavior: smooth; */
  }

body {
  min-height: 100vh;
  overflow-x: hidden;
  background-color:  #ecf0f5 !important;
}

.container {
  position: relative;
  width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: #e65b32; 
  border-radius: 10px;
}








/* =============== Navigation ================ */
.navigation {
  position: fixed;
  /* width: 220px; */
  height: 100%;
  /* background: var(--blue); */
  /* border-left: 10px solid var(--blue); */
  transition: 0.5s;
  overflow: scroll;
  /* scrollbar-width: none; */

  scroll-behavior: smooth;
  scrollbar-color: red;
  
}
/* .navigation.active {
  width: 80px;
} */

/* .navigation ul {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: 0%;
}

.navigation ul li {
  position: relative;
  width: 100%;
  
  list-style: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.navigation ul li:hover,
.navigation ul li.hovered {
  background-color: var(--white);
}

.navigation ul li:nth-child(1) {
  margin-bottom: 20px;
  pointer-events: none;
}

.navigation ul li a {
  position: relative;
  display: block;
  width: 100%;
  display: flex;
  text-decoration: none;
  color: white;
}
.navigation ul li:hover a,
.navigation ul li.hovered a {
  color: var(--blue);
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  min-width: 60px;
  height: 60px;
  line-height: 75px;
  text-align: center;
} */
/* .navigation ul li a .icon ion-icon {
  font-size: 1.20rem;
}

.navigation ul li a .title {
  position: relative;
  display: block;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  text-align: start;
  white-space: nowrap;
  font-size: 0.9rem;


} */

/* --------- curve outside ---------- */
/* .navigation ul li:hover a::before,
.navigation ul li.hovered a::before {
  content: "";
  position: absolute;
  right: 0;
  top: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px 35px 0 10px var(--white);
  pointer-events: none;
}
.navigation ul li:hover a::after,
.navigation ul li.hovered a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: -50px;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 35px -35px 0 10px var(--white);
  pointer-events: none;
} */


.highcharts-credits {
  display: none !important;
  }

/* ===================== Main ===================== */
.main {
  position: absolute;
  width: calc(100% - 240px);
  left: 250px;
  min-height: 100vh;
  background: #ecf0f5;
  transition: 0.5s;
}
.main.active {
  width: calc(100% - 50px);
  left: 53px;
}

.topbar {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
  
}

.toggle {
  position: relative;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  cursor: pointer;
}

.search {
  position: relative;
  width: 400px;
  margin: 0 10px;
}

.search label {
  position: relative;
  width: 100%;
}

.search label input {
  width: 100%;
  height: 40px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid var(--black2);
}

.search label ion-icon {
  position: absolute;
  top: 0;
  left: 10px;
  font-size: 1.2rem;
}

.user {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.user img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}




/* ======================= usersdata tabale ====================== */


@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container{
  display: flex;
  justify-content: center;
align-items: center;
 z-index: 10;
 width: 100%;
 height: 100vh;
 backdrop-filter: blur(5px);
 position: fixed;
 
}

.myprofildrop{
position: absolute;
right: 0px;
top: 50px;
/* background-color: red; */
}

.myaaaa{
  cursor: pointer;
}

.myreactlod{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  
}


.usertablicon{
  cursor: pointer;
  margin: 0px 2px;
  font-size: 16px;
}

.zYAJf{
  text-align: start !important;
}


.dropdown {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid gray;
  border-top: 0;
  overflow: auto;
  margin: 5px 0px;
}
.dropdown > div {
  text-align: left;
  height: 35px;
  padding: 8px;
  color: rgb(43, 43, 43);
  border-top: 1px solid gray;
  cursor: pointer;
}
.dropdown > div:first-child {
  border-top: 0;
}

.autoComplete {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.inputBox {
  border-radius: 6px;
  border: 1px solid gray;
  font-size: 16px;
  padding: 8px;
  
}
.inputBox:focus {
  outline: 0;
  border: 1px solid rgb(8, 8, 8);
}





.profilecard1 {
  background-color: white;
  padding: 20px 15px;
  width: 25%;
  border-radius: 5px;
  text-align: center;
  margin: 10px;


}

.profilecard2 {
  background-color: white;
  /* padding: 20px 15px; */
  width: 75%;
  border-radius: 5px;
  /* text-align: center; */
  margin: 10px;
}

.maindiv {
  display: flex;
  /* margin: auto;
  background-color: #f00; */
 
}

.profileimg{
  height: 50px;
  width: 50px;
  border: 3px solid gray ;
  border-radius: 50px;
  margin: auto;
}
.paragraphprofile{
  font-size: 14px;
  color: gray;
  
}

.textcompo{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* background-color: red; */
  height: 35px;
  border: 1px solid gray;
  padding: 0px 5px;
  margin: 3px 0px;
}
.optionactivity{
  width: fit-content;
  margin: 0px 10px;
  padding: 0px 20px;
}



.viewpromocls{
  display: flex;
  position: relative;
  
}

.ontablehover:hover{
  background-color: darkgrey;
}












/* For Mobile screen  */

@media (max-width:500px) {

  .viewpromocls{
    display: block;
  }

  .profilecard1 {
      background-color: white;
      padding: 20px 15px;
      width: 100%;
      border-radius: 5px;
      text-align: center;
      margin: 10px;
  }

  .maindiv {
      display: block;
  }
  .profilecard2 {
      background-color: white;
      /* padding: 20px 15px; */
      width: 100%;
      border-radius: 5px;
      /* text-align: center; */
      margin: 10px;
  }





}







/* ======================= Cards ====================== */
.cardBox {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.cardBox .card {
  position: relative;
  background: var(--white);
  padding: 5px;
  border-radius: 20px;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
}

.cardBox .card .numbers {
  position: relative;
  font-weight: 500;
  font-size: 1.1rem;
  color: var(--blue);
}

.cardBox .card .cardName {
  color: var(--black2);
  font-size: 0.8rem;
  /* margin-top: 5px; */
}

.cardBox .card .iconBx {
  font-size: 1.5rem;
  color: var(--black2);
}

.cardBox .card:hover {
  background: var(--blue);
}
.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBx {
  color: var(--white);
}

/* ================== Order Details List ============== */
.details {
  position: relative;
  width: 100%;
  padding: 20px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  /* margin-top: 10px; */
}

.details .recentOrders {
  position: relative;
  display: grid;
  min-height: 500px;
  background: var(--white);
  padding: 20px;
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.details .cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.cardHeader h2 {
  font-weight: 600;
  color: var(--blue);
}
.cardHeader .btn {
  position: relative;
  padding: 5px 10px;
  background: var(--blue);
  text-decoration: none;
  color: var(--white);
  border-radius: 6px;
}

.details table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.details table thead td {
  font-weight: 600;
}
.details .recentOrders table tr {
  color: var(--black1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.details .recentOrders table tr:last-child {
  border-bottom: none;
}
.details .recentOrders table tbody tr:hover {
  background: var(--blue);
  color: var(--white);
}
.details .recentOrders table tr td {
  padding: 10px;
}
.details .recentOrders table tr td:last-child {
  text-align: end;
}
.details .recentOrders table tr td:nth-child(2) {
  text-align: end;
}
.details .recentOrders table tr td:nth-child(3) {
  text-align: center;
}
.status.delivered {
  padding: 2px 4px;
  background: #8de02c;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.status.pending {
  padding: 2px 4px;
  background: #e9b10a;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.status.return {
  padding: 2px 4px;
  background: #f00;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.status.inProgress {
  padding: 2px 4px;
  background: #1795ce;
  color: var(--white);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}

.recentCustomers {
  position: relative;
  display: grid;
  min-height: 500px;
  padding: 20px;
  background: var(--white);
  box-shadow: 0 7px 25px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}
.recentCustomers .imgBx {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  overflow: hidden;
}
.recentCustomers .imgBx img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recentCustomers table tr td {
  padding: 12px 10px;
}
.recentCustomers table tr td h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2rem;
}
.recentCustomers table tr td h4 span {
  font-size: 14px;
  color: var(--black2);
}
.recentCustomers table tr:hover {
  background: var(--blue);
  color: var(--white);
}
.recentCustomers table tr:hover td h4 span {
  color: var(--white);
}

/* ====================== Responsive Design ========================== */
@media (max-width: 991px) {
  /* .navigation {
    left: -5px;
  }
  .navigation.active {
    width: 300px;
    left: 60px;
  } */
  .main {
    /* width: 100%; */
    left: 0px;
    width: calc(100% - -115px);

  }
  .main.active {
    left: 250px;
    width: calc(100% - -115px);

  }
  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .details {
    grid-template-columns: 1fr;
  }
  .recentOrders {
    overflow-x: auto;
  }
  .status.inProgress {
    white-space: nowrap;
  }
}

@media (max-width: 480px) {
  /* .cardBox {
    grid-template-columns: repeat(1, 1fr);
  }
  .cardHeader h2 {
    font-size: 20px;
  }
  .user {
    min-width: 40px;
  }
  .navigation {
    width: 100%;
    left: -100%;
    z-index: 1000;
  } */
  /* .navigation.active {
    width: 100%;
    left: 0;
  } */
  .toggle {
    z-index: 10001;
  }
  .main.active .toggle {
    color: #fff;
    position: fixed;
    right: 0;
    left: initial;
  }
}






























:root {

    --safety-orange: #292e57;
    --lavender-gray: hsl(230, 19%, 81%);
    --persian-rose: #292e57;
    --red-crayola: hsl(341, 100%, 49%);
    --eerie-black: hsl(240, 6%, 10%);
    --light-gray: hsl(0, 0%, 80%);
    --cultured-2: hsl(210, 60%, 98%);
    --platinum: hsl(0, 0%, 90%);
    --gray-web: hsl(220, 5%, 49%);
    --cultured: hsl(0, 0%, 93%);
    --black_10: hsla(0, 0%, 0%, 0.1);
    --black_5: hsla(0, 0%, 0%, 0.05);
    --white-1: hsl(0, 0%, 100%);
    --white-2: hsl(0, 14%, 98%);
    --black: hsl(0, 0%, 0%);
  
    /**
     * gradient color
     */
  
    --gradient: linear-gradient(to left top, var(--persian-rose), var(--safety-orange));
  
    /**
     * typography
     */
  
    --ff-roboto: 'Roboto', sans-serif;
    --ff-league-spartan: 'League Spartan', sans-serif;
  
    --fs-1: 3.5rem;
    --fs-2: 3rem;
    --fs-3: 2.1rem;
    --fs-3-1: 2.3rem;
    --fs-4: 1.7rem;
    --fs-5: 1.4rem;
    --fs-6: 1.3rem;
    --fs-7: 1.1rem;
    --fs-8: 1rem;
  
    --fw-700: 700;
    --fw-500: 500;
  
    /**
     * spacing
     */
  
    --section-padding: 60px;
  
    /**
     * shadow
     */
  
    --shadow-1: 0 6px 24px var(--black_5);
    --shadow-2: 0 2px 28px var(--black_10);
  
    /**
     * border radius
     */
  
    --radius-2: 2px;
    --radius-5: 5px;
    --radius-8: 8px;
  
    /**
     * transition
     */
  
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease;
    --cubic-out: cubic-bezier(0.33, 0.85, 0.4, 0.96);
  
  }
  
  
  
  
  
  /*-----------------------------------*\
    #RESET
  \*-----------------------------------*/
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  li { list-style: none; }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  


  ion-icon { display: block; }
  
  img { height: auto; }
  


  

  
  button { cursor: pointer; }
  
  ion-icon { pointer-events: none; }
  
  address { font-style: normal; }
  
  html {
    /* font-family: var(--ff-roboto); */
    /* font-size: 10px ; */
    scroll-behavior: smooth;
  }
  
  body {
    background-color: var(--white-1);
    /* color: var(--gray-web); */
    /* font-size: 1.6rem; */
    line-height: 1.8;
  }
  
  ::-webkit-scrollbar { width: 10px; }
  
  ::-webkit-scrollbar-track { background-color: hsl(0, 0%, 98%); }
  
  ::-webkit-scrollbar-thumb { background-color: #292e57; }
  
  ::-webkit-scrollbar-thumb:hover { background-color: #292e57; }
  
  :focus-visible { outline-offset: 4px; }
  
  
  

  .row{
    display: flex;
    flex-wrap: wrap;
  }
  ul{
    list-style: none;
  }
  .footer{
    background-color:  #80263f ;
      /* padding: 70px 0; */
  }
  .footer-col{
     width: 24%;
     padding: 0 1px;
  }
  .footer-col h4{
    font-size: 18px;
    color: #ffffff;
    text-transform: capitalize;
    margin-bottom: 35px;
    font-weight: 500;
    position: relative;
  }
 
  .footer-col ul li:not(:last-child){
    margin-bottom: 10px;
  }
  .footer-col ul li a{
    font-size: 16px;
    text-transform: capitalize;
    color: #ffffff;
    text-decoration: none;
    font-weight: 300;
    color: #ffffff;
    display: block;
    transition: all 0.3s ease;
  }
  .footer-col ul li a:hover{
    color: #ffffff;
    padding-left: 8px;
  }
  .footer-col .social-links a{
    display: inline-block;
    height: 40px;
    width: 40px;
    background-color: rgba(255,255,255,0.2);
    margin:0 10px 10px 0;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    color: #ffffff;
    transition: all 0.5s ease;
  }
  .footer-col .social-links a:hover{
    color: #24262b;
    background-color: #ffffff;
  }

  @media(max-width: 767px){
    .footer-col{
      width: 50%;
      margin-bottom: 30px;
  }
  }
  @media(max-width: 574px){
    .footer-col{
      width: 100%;
  }
  }
  
  
  /*-----------------------------------*\
    #REUSED STYLE
  \*-----------------------------------*/
  
  .container { padding-inline: 15px; }
  
  .btn {
    max-width: max-content;
    color: var(--white-1);
    font-size: var(--fs-8);
    font-weight: var(--fw-700);
    padding: 10px 30px;
    border-radius: var(--radius-5);
    transition: var(--transition-1);
  }
  
  .btn-primary {
    background-image: var(--gradient);
    background-size: 1000%;
  }

  
  
  .btn-primary:is(:hover, :focus) { background-position: bottom right; }
  
  .btn-secondary {
    background-color: var(--white-1);
    color: var(--eerie-black);
  }
  
  .btn-secondary:is(:hover, :focus) {
    background-color: var(--eerie-black);
    color: var(--white-1);
  }
  
  .section { padding-block: var(--section-padding); }
  
  .h1,
  .h2,
  .h3 {
    color: var(--eerie-black);
    font-family: var(--ff-league-spartan);
    line-height: 1.2;
  }
  
  .h1 { font-size: var(--fs-1); }
  
  .h2 { font-size: var(--fs-2); }
  
  .h3 { font-size: var(--fs-3); }

  .h4 { font-size: var(--fs-3-1); }
  
  .w-100 { width: 100%; }
  
  .section-title,
  .section-text { text-align: center; }
  
  .section-text { font-size: var(--fs-6); }
  
  .grid-list {
    display: grid;
    gap: 30px;
  }
  
  .img-holder {
    aspect-ratio: var(--width) / var(--height);
    background-color: var(--light-gray);
  }
  
  .img-cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #HEADER
  \*-----------------------------------*/
  
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--white-1);
    padding-block: 15px;
    border-block-end: 1px solid var(--cultured);
    z-index: 4;
    transition: var(--transition-1);
    margin-bottom: 15px;
    font-size: 10px;
  }
  
  .header.active { filter: drop-shadow(var(--shadow-2)); }
  
  .header > .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
  }
  
  .logo {
    font-family: var(--ff-league-spartan);
    color: var(--black);
    font-size: 3.5rem;
    font-weight: var(--fw-700);
    line-height: 1;
  }

  .logoimg{
    height: 35px;
    width: 150px;
    
  }
  
  .nav-toggle-btn { font-size: 40px; }
  
  .nav-toggle-btn.active .open,
  .nav-toggle-btn .close { display: none; }
  
  .nav-toggle-btn .open,
  .nav-toggle-btn.active .close { display: block; }
  
  .navbar {
    background-color: var(--white-1);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    max-width: calc(100% - 30px);
    width: 100%;
    padding-inline: 0;
    border: 1px solid var(--cultured);
    transition: 0.3s var(--cubic-out);
    max-height: 0;
    visibility: hidden;
    overflow: hidden;
  }
  
  .navbar.active {
    max-height: 320px;
    visibility: visible;
    transition-duration: 0.5s;
  }
  
  .navbar-list {
    padding: 20px 15px;
    padding-block-start: 10px;
    opacity: 0;
    transition: var(--transition-1);
  }
  
  .navbar.active .navbar-list { opacity: 1; }
  
  .navbar-link {
    color: var(--eerie-black);
    font-family: var(--ff-league-spartan);
    font-size: var(--fs-8);
    line-height: 2.5;
    
    padding-block: 8px;
    transition: var(--transition-1);
  }
  
  .navbar-link:is(:hover, :focus) { color: var(--red-crayola); }
  
  .header .btn { margin-block-start: 10px; }
  
  
  
  
  
  /*-----------------------------------*\
    #HEO
  \*-----------------------------------*/
  
  .hero {
    padding-block-start: calc(var(--section-padding) + 50px);
    text-align: center;
  }
  
  .hero-content { margin-block-end: 30px; }
  
  .hero-subtitle {
    color: var(--eerie-black);
    font-size: var(--fs-5);
    font-weight: var(--fw-500);
  }
  
  .hero-title { margin-block: 12px 8px; }
  
  .hero-text { font-size: var(--fs-7);color: gray; }
  
  .hero .btn {
    margin-inline: auto;
    margin-block-start: 20px;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #SERVICE
  \*-----------------------------------*/
  
  .service { background-color: var(--white-2); }
  
  .service .section-text { margin-block: 5px 35px; }
  
  .service-card {
    background-color: var(--white-1);
    padding: 20px 15px;
    border: 1px solid var(--platinum);
    border-radius: var(--radius-5);
    text-align: center;
    box-shadow: var(--shadow-1);
    transition: var(--transition-2);
    
  }
  
  .service-card:is(:hover, :focus-within) { transform: translateY(-10px); }
  
  .service-card .card-icon {
    color: var(--white-1);
    font-size: 25px;
    max-width: max-content;
    margin-inline: auto;
    padding: 18px;
    border-radius: 50%;
  }
  
  .service-card .card-title { margin-block: 20px 8px; }
  
  .service-card .card-text { font-size: var(--fs-6); }
  
  
  
  
  
  /*-----------------------------------*\
    #PROJECT
  \*-----------------------------------*/
  
  .project .section-text { margin-block: 5px 35px; }
  
  .project-card {
    position: relative;
    border-radius: var(--radius-8);
    overflow: hidden;
  }
  
  .project-card::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--black);
    opacity: 0.5;
    transition: var(--transition-2);
  }
  
  .project-card:is(:hover, :focus-within)::after { opacity: 0.8; }
  
  .project-card .card-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    z-index: 1;
    transition: var(--transition-2);
  }
  
  .project-card:is(:hover, :focus-within) .card-content { transform: translateY(-20px); }
  
  .project-card .card-subtitle {
    color: var(--lavender-gray);
    font-size: var(--fs-6);
    line-height: 1;
  }
  
  .project-card .card-title {
    color: var(--white-1);
    margin-block: 12px 15px;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #ABOUT
  \*-----------------------------------*/
  
  .about { background-color: var(--white-2); }
  
  .about-banner {
    position: relative;
    border-radius: var(--radius-5);
    overflow: hidden;
    margin-block-end: 25px;
  }
  
  .play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white-1);
    color: var(--red-crayola);
    font-size: 32px;
    padding: 16px;
    border-radius: 50%;
    animation: pulse 1.5s ease-out infinite;
  }
  
  @keyframes pulse {
    0% { box-shadow: 0 0 0 1px var(--white-1); }
    100% { box-shadow: 0 0 0 25px transparent; }
  }
  
  .about :is(.section-title, .section-text) { text-align: left; }
  
  .about .section-title { margin-block-end: 5px; }
  
  .about .section-text:not(:last-child) { margin-block-end: 18px; }
  
  .about .h3 { margin-block-end: 8px; }
  
  .about-list { margin-block: 15px 18px; }
  
  .about-item {
    display: flex;
    align-items: flex-start;
    gap: 5px;
  }
  
  .about-item ion-icon {
    color: var(--red-crayola);
    flex-shrink: 0;
    margin-block-start: 2px;
  }
  
  .about-item:not(:last-child) { margin-block-end: 12px; }
  
  
  
  
  
  /*-----------------------------------*\
    #CTA
  \*-----------------------------------*/
  
  .cta {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: 1;
  }
  
  .cta::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--gradient);
    opacity: 0.95;
    z-index: -1;
  }
  
  .cta-subtitle,
  .cta .section-title { color: var(--white-1); }
  
  .cta-subtitle {
    font-size: var(--fs-6);
    font-weight: var(--fw-500);
    text-align: center;
  }
  
  .cta .section-title { margin-block: 12px 18px; }
  
  .cta .btn { margin-inline: auto; }
  
  
  
  
  
  /*-----------------------------------*\
    #BLOG
  \*-----------------------------------*/
  
  .blog .section-text { margin-block: 5px 35px; }
  
  .blog-card { background-color: var(--white-2); }
  
  .blog-card .card-banner { overflow: hidden; }
  
  .blog-card .card-banner img { transition: var(--transition-2); }
  
  .blog-card:is(:hover, :focus-within) .card-banner img { transform: scale(1.05); }
  
  .blog-card .card-content { padding: 15px; }
  
  .blog-card .time {
    color: var(--red-crayola);
    font-size: var(--fs-6);
    line-height: 1;
    margin-block-end: 10px;
  }
  
  .blog-card .card-title { transition: var(--transition-1); }
  
  .blog-card .card-title:is(:hover, :focus) { color: var(--red-crayola); }
  
  
  
  
  
  /*-----------------------------------*\
    #CONTACT
  \*-----------------------------------*/
  
  .contact { background-color: var(--white-2); }
  
  .contact .section-text { margin-block: 5px 35px; }
  
  .contact-form {
    background-color: var(--white-1);
    padding: 20px;
    border-radius: var(--radius-2);
    margin-block-end: 30px;
    box-shadow: var(--shadow-1);
  }
  
 
 
  

  
  .label-link {
    display: inline-block;
    color: var(--red-crayola);
  }
  

  
  .contact-list {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    gap: 30px 15px;
  }
  
  .contact-item { min-width: 100%; }
  
  .contact-card {
    background-color: var(--white-1);
    padding: 20px;
    border-radius: var(--radius-2);
    box-shadow: var(--shadow-1);
    display: flex;
    align-items: flex-start;
    gap: 15px;
  }
  
  .contact-card .card-icon {
    background-color: var(--cultured-2);
    color: var(--red-crayola);
    font-size: 25px;
    padding: 13px;
    border-radius: 50%;
    transition: var(--transition-1);
  }
  
  .contact-card:is(:hover, :focus) .card-icon {
    background-color: var(--red-crayola);
    color: var(--white-1);
  }
  
  .contact-card .card-title { margin-block-end: 5px; }
  
  .contact-card :is(.card-link, .card-address) {
    font-size: var(--fs-6);
    transition: var(--transition-1);
  }
  
  .contact-card .card-link:is(:hover, :focus) { color: var(--red-crayola); }
  
  
  
  
  
  /*-----------------------------------*\
    #FOOTER
  \*-----------------------------------*/
  
  .footer {
    background-color: var(--white-2);
    padding-block: 20px;
  }
  
  .copyright {
    font-size: var(--fs-6);
    text-align: center;
  }
  
  .copyright-link {
    color: var(--red-crayola);
    font-weight: var(--fw-500);
    display: inline-block;
  }
  
  
  
  
  
  /*-----------------------------------*\
    #BACK TO TOP
  \*-----------------------------------*/
  
  .back-top-btn {
    position: fixed;
    bottom: 10px;
    right: 20px;
    background-color: var(--eerie-black);
    color: var(--white-1);
    padding: 12px;
    border-radius: 50%;
    box-shadow: var(--shadow-2);
    z-index: 4;
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-1);
  }
  
  .back-top-btn.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(-10px);
  }
  
  .back-top-btn:is(:hover, :focus) { opacity: 0.9; }
  
  
  
  
  
  /*-----------------------------------*\
    #MEDIA QUERIES
  \*-----------------------------------*/
  
  /**
   * responsive for larger than 575px screen
   */
  
  @media (min-width: 575px) {
  
    /**
     * REUSED STYLE
     */
  
    .container {
      max-width: 540px;
      width: 100%;
      margin-inline: auto;
    }
  
    .grid-list {
      grid-template-columns: 1fr 1fr;
      column-gap: 25px;
    }
  
  
  
    /**
     * BLOG
     */
  
    .blog .grid-list { grid-template-columns: 1fr; }
  
    .blog-card.grid {
      display: grid;
      grid-template-columns: 0.47fr 1fr;
      align-items: center;
      gap: 10px;
    }
  
  
  
    /**
     * CONTACT
     */
  

    .contact-item { min-width: calc(50% - 18px); }
  
  }
  
  
  
  
  
  /**
   * responsive for larger than 768px screen
   */
  
  @media (min-width: 768px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 4.5rem;
      --fs-2: 3.7rem;
      --fs-3: 2.3rem;
      --fs-4: 1.8rem;
      --fs-5: 1.5rem;
      --fs-6: 1.4rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 720px; }
  
    .section-text {
      max-width: 65ch;
      margin-inline: auto;
    }
  
  
  
    /**
     * HERO
     */
  
    .hero-text {
      max-width: 60ch;
      margin-inline: auto;
      color: gray;
    }
  
  
  
    /**
     * ABOUT
     */
  
    .about-banner { max-width: 550px; }
  
    .about .section-text {
      max-width: unset;
      margin-inline: 0;
    }
  
  
  
    /**
     * CONTACT
     */
  
    .contact-form { padding: 30px; }
  
    .contact-item { min-width: calc(50% - 12.5px); }
  
  }
  
  
  
  
  
  /**
   * responsive for larger than 992px screen
   */
  
  @media (min-width: 992px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 5rem;
      --fs-2: 4rem;
      --fs-3: 2.5rem;
  
      /**
       * spacing
       */
  
      --section-padding: 100px;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 960px; }
  
    .grid-list { grid-template-columns: repeat(3, 1fr); }
  
  
  
    /**
     * HEADER
     */
  
    .nav-toggle-btn { display: none; }
  
    .navbar,
    .navbar.active { all: unset; }
  
    .navbar-list {
      all: unset;
      display: flex;
      align-items: center;
      gap: 25px;
    }
  
    .navbar-link { position: relative; }
  
    .navbar-link:is(:hover, :focus) { color: var(--eerie-black); }
  
    .navbar-link::after {
      content: "";
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      height: 2px;
      background-image: var(--gradient);
      transform: scaleX(0);
      transform-origin: left;
      transition: var(--transition-2);
    }
  
    .navbar-link:is(:hover, :focus)::after { transform: scaleX(1); }
  
    .header .btn { margin-block-start: 0; }
  
  
  
    /**
     * HERO
     */
  
    .hero .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 30px;
      text-align: left;
    }
  
    .hero-content { margin-block-end: 0; }
  
    .hero-subtitle {
      position: relative;
      padding-inline-start: 15px;
    }
  
    .hero-subtitle::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: var(--red-crayola);
    }
  
    .hero-text,
    .hero .btn { margin-inline: 0; }
  
  
  
    /**
     * SERVICE
     */
  
    .service-card { padding: 20px; }
  
    .service-card .h3 { --fs-3: 2.3rem; }
  
  
  
    /**
     * PROJECT
     */
  
    .project-card .card-subtitle { --fs-6: 1.5rem; }
  
  
  
    /**
     * ABOUT
     */
  
    .about .container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      gap: 30px;
    }
  
    .about-banner { margin-block-end: 0; }
  
  
  
    /**
     * BLOG
     */
  
    .blog .grid-list {
      grid-template-columns: 1fr 1fr;
      row-gap: 20px;
    }
  
    .blog .grid-list > li:first-child {
      grid-column: 1 / 2;
      grid-row: 1 / 5;
    }
  
    .blog-card:not(.grid) { height: 100%; }
  
    .blog-card .card-content { padding-block: 10px; }
  
    .blog-card:not(.grid) .card-content { padding: 25px; }
  
    .blog-card.grid .h3 { --fs-3: 2rem; }
  
    .blog-card .time { --fs-6: 1.6rem; }
  
  
  
    /**
     * CONTACT
     */
  
    .checkbox-wrapper { margin-block: 20px; }
  
    .contact-item { min-width: calc(33.33% - 16.66px); }
  
    .contact-card { padding: 30px; }
  
    .contact-card .card-icon { font-size: 32px; }
  
  }
  
  
  
  
  
  /**
   * responsive for larger than 1200px screen
   */
  
  @media (min-width: 1200px) {
  
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
  
      /**
       * typography
       */
  
      --fs-1: 6.8rem;
      --fs-2: 4.5rem;
      --fs-4: 1.9rem;
      --fs-5: 1.6rem;
      --fs-6: 1.6rem;
  
    }
  
  
  
    /**
     * REUSED STYLE
     */
  
    .container { max-width: 1140px; }
  
    .btn { --fs-6: 1.5rem; }
  
  
  
    /**
     * SERVICE
     */
  
    .service-card { padding: 30px; }
  
    .service-card .h3 { --fs-3: 2.5rem; }
  
  
  
    /**
     * PROJECT
     */
  
    .project-card .card-content { padding: 30px; }
  
    .project-card .card-subtitle { --fs-6: 1.6rem; }
  
  
  
    /**
     * ABOUT
     */
  
    .about .container { gap: 60px; }
  
    .about-item { gap: 7px; }
  
    .about-item ion-icon { font-size: 20px; }
  
  
  
    /**
     * CTA
     */
  
    .cta .section-title { margin-block: 15px 24px; }
  
  
  
    /**
     * BLOG
     */
  
    .blog-card .time { --fs-6: 1.6rem; }
  
    .blog-card.grid .h3 { --fs-3: 2.4rem; }
  
  
  
    /**
     * CONTACT
     */
  
  
  }